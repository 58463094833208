<template>
  <div>
    <label
      class="cursor-pointer"
      style="margin-top: 3px;"
      @click="showNotificationModal = true"
    >
      <feather-icon
        :badge="unreadNotfications"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="10"
      />
    </label>

    <b-modal
      id="createJobModal"
      v-model="showNotificationModal"
      hide-backdrop
      scrollable
      centered
      hide-footer
      size="notifications"
      title="Notificaciones"
      content-class="shadow"
      @close="$emit('update:show-notification-modal', false)"
    >
      <!-- no-close-on-backdrop -->
      <!-- Notifications -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <!-- Events Notification -->
        <b-link
          v-for="event in notifications"
          :key="event.hash"
          class="text-dark"
          @click.stop="goNotification(event)"
        >
          <b-media :class="{'bg-notifications': event.is_read}">
            <template #aside>
              <b-avatar
                size="32"
                :variant="`light-${event.color}`"
              >
                <i :class="event.icon" />
              </b-avatar>
            </template>
            <p
              class="media-heading"
            >
              <span :class="[event.is_read ? 'font-weight-normal' : 'font-weight-bolder']">
                {{ event.title }}
              </span>
            </p>
            <small
              v-if="event.description"
              class="notification-text"
            >{{ event.description.length > 85 ? event.description.substring(0,85)+"..." : event.description }}</small><br>
          </b-media>
        </b-link>
      </vue-perfect-scrollbar>
    </b-modal>
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import { mapGetters, mapActions } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
// import store from '@/store'
import { formatDateToMonthShort } from '@core/utils/filter'

export default {
  components: {
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showNotificationModal: false,
      navMenuItems,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      unreadNotfications: 'notificationStore/unreadNotfications',
      notifications: 'notificationStore/notifications',
    }),
  },
  mounted() {
    this.fetchCounterNotifications()
  },
  methods: {
    formatDateToMonthShort,
    ...mapActions({
      fetchCounterNotifications: 'notificationStore/fetchCounterNotifications',
      fetchNotifications: 'notificationStore/fetchData',
      updateStatusNotification: 'notificationStore/updateStatus',
    }),
    async goNotification(event) {
      if (event.notification_type.name === 'EVENT') {
        this.$emit('show-event', true)
      }
      if (event.notification_type.name === 'TODO' && this.$route.name !== 'todo') {
        this.$router.push({ name: 'todo' })
      }
      this.showNotificationModal = false
      await this.updateStatusNotification(event.hash).then(async () => {
        await this.fetchNotifications()
        await this.fetchCounterNotifications()
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import '~@core/scss/base/core/colors/palette-variables.scss';
.scroll-area {
  position: relative;
  margin: auto;
  width: 99%;
  height: 100%;
  max-height: 550px;
}
 .media-list .media {
  padding: 0.4rem;
  margin-top: 0;
}
.text-gray {
  color: grey !important;
}
 .modal {
  background-color: rgba(255, 255, 255, 0.3) !important;
}
.badge.badge-up {
  position: absolute;
  top: -10px;
  min-width: 1.10rem !important;
  min-height: 1.10rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.786rem;
  line-height: 0.786;
}
</style>
